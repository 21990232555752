export const ID_WECHAT = 'Dinhductu'
export const ID_SKYPE = 'xeM5tNWsBbot'
export const ID_LINE = 'K5yXobgat'
export const ID_WHATSAPP = '0978198189'
export const SOCIAL = {
  fb: 'https://www.facebook.com/simplus.net.vn/',
  tiktok: 'https://www.tiktok.com/@simplus_dungrededang',
  zalo: 'https://zalo.me/0394516666',
  yt: 'https://www.youtube.com/channel/UCs4oD8qZ0dtCnS5Ohsgk3tg',
}
// export const PHONE_NUMBER = '0978 198 189'
// export const HOTLINE = '0888 000 800'
export const HOTLINE = '19008699'

// export const API_BASE_URL = 'https://customer-api-stg.simplus.space'

// export const API_BASE_URL_TEMPORARY = 'https://customer-api-stg.simplus.space'
export const API_BASE_URL =
  process.env.NEXT_API_BASE_URL ||
  process.env.NEXT_PUBLIC_API_BASE_URL ||
  (typeof window !== 'undefined' ? (window)?.API_BASE_URL : null) || 'https://customer-api-dev.simplus.space/';

// 'https://customer-api-dev.simplus.space'

export const API_BASE_URL_TEMPORARY = API_BASE_URL;
// export const API_BASE_URL =
//   'https://customer-api-stg.simplus.space'

// export const API_BASE_URL_TEMPORARY = 'https://customer-api-stg.simplus.space'

export const ACCESS_TOKEN = 'accessToken'
export const REFRESH_TOKEN = 'refreshToken'
export const USER_INFORMATION = 'userInformation'

export const ITEMS_PER_PAGE = 24
export const BRAND_NAME = 'Công ty TNHH MTV SIMPLUS'
export const TOURISTS_TO_VIETNAM_TYPE = 'Travel VN'
export const TRAVEL_ABROAD_TYPE = 'Travel International'
export const M2M_TYPE = 'M2M'
export const regexPhoneNumber = /^(0?)(3[2-9]|(52|55|56|58)|7[0|6-9]|8[0-9]|9[0-4|6-8])[0-9]{7}$|(016|012)[0-9]{8}$/
