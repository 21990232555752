import { useRouter } from 'next/router'
import Link from 'next/link'
import languageDetector from '../../../lib/languageDetector'
import Image from 'next/image'

const LanguageSwitchLink = ({
  setOpen,
  currentLanguage,
  locale,
  item,
  isM2M,
  ...rest
}) => {
  const router = useRouter()
  let href = rest.href || router.asPath
  let pName = router.pathname
  if (pName === (isM2M ? '/landing/m2m-sim-card' : '/')) {
    if (locale) {
      href = `${isM2M ? '/landing/m2m-sim-card' : '/'}${locale}`
    }
  } else {
    Object.keys(router.query).forEach((k) => {
      if (k === 'locale') {
        pName = pName.replace(`[${k}]`, locale)
        if (pName ===  (isM2M ? '/landing/m2m-sim-card/en' : '/en')) pName = isM2M ? '/landing/m2m-sim-card' : '/'
        return
      }
      pName = pName.replace(`[${k}]`, router.query[k])
    })
    if (locale) {
      href = rest.href
        ? locale !== 'en'
          ? `${rest.href}/${locale}`
          : rest.href
        : pName
    }
  }

  return (
    <Link href={href}>
      <div
        className={`dd-lang-item ${currentLanguage === item.id && 'active'}`}
        onClick={() => {
          setOpen(false)
          languageDetector.cache(locale)
        }}
      >
        <Image
          width={30}
          height={24}
          src={`/images/common/${item.id}.png`}
          alt={item.id}
        />
        {item.name}
      </div>
    </Link>
  )
}

export default LanguageSwitchLink
